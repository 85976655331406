<template>
  <button class="btn flex items-center justify-center">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'MaterialButton'
}
</script>

<style scoped>
.btn {
  box-shadow: 0 1px 4px rgba(0, 0, 0, .6);
  transition: background-color .3s;
  @apply bg-blue-500 text-white h-11;
}
.btn:hover, .btn:focus {
  @apply bg-blue-500;
}
.btn > * {
  position: relative;
}
.btn span {
  display: block;
}
.btn:before {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  padding-top: 0;
  border-radius: 100%;
  background-color: rgba(236, 240, 241, .3);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.btn:active:before {
  transition: width .2s ease-out, padding-top .2s ease-out;
}
</style>
